
<template>
  <div v-if="data.detail" class="main-layout">

    <div class="back-info" @click="back">
      返回地图
    </div>

    <img :src="data.detail.img" alt="">

    <div class="bottom">
      <div class="left-btn" @click = 'clockCard'>
        <img src="../../../assets/home/icon_scan.png" alt="">
        <span>打卡领福利</span>
      </div>
      <div  class="right-btn" @click="toGuide">
        <img src="../../../assets/home/icon_guide.png" alt="">
        <span>导航</span>
      </div>

    </div>


  </div>
</template>
<script>
import { ref, reactive, onMounted, toRefs, getCurrentInstance } from "vue";
import {useRoute, useRouter} from "vue-router";
import Certification from "@/components/certification";
import TopBar from "@/components/topBar";
import axios from 'axios'
import {clearToast, setStore, showFailToast, showLoading} from "../../../utils/util";
import {Dialog} from "vant";
export default {
  name: "store-detail",
  setup() {
    const { proxy } = getCurrentInstance();
    const $router = useRouter();
    const $route = useRoute();
    const data = reactive({
      detail: null,
      storeId:$route.query.id ?? ''
    })
    onMounted(async () => {

    });

    const loadData = () => {


      showLoading()
      proxy.$http.get("/wenwu-custom-services/bookstore/detail?id="+data.storeId).then(res => {
        data.detail =  res.data.data


        //埋点上报
        eshimin.bytedanceApplogEvent({
          eventName: 'book_detail', // 例子
          eventParams: {'page_name': '会场详情页', 'venue_name': data.detail.name} ,//例子
          onResponse:  (res)=> {
            console.log("bytedanceApplogEvent responseData:" + res);
          }
        })

        clearToast()
      })
    }

    const back = () => {
      $router.go(-1);
    }

    const toGuide = () => {

      //判断是否是主会场
      let currentTime = new Date();//获取当前时间
      let startTime = Date.parse('2023-03-24 00:00:00'.replace(/-/g, "/"));
      let dateDiff = new Date(startTime).getTime() - currentTime.getTime();//时间差的毫秒数
      if(data.detail.mainVenue == 1 &&  dateDiff > 0){

        showFailToast('主会场24日开放');

      }else{
        //获取当前位置
        eshimin.getAddress( {
          onResponse: (res) => {
            res = JSON.parse(res);
            // if (res.code === 200) {

            window.location.href = 'https://uri.amap.com/navigation?from=' + res.longitude + ',' + res.latitude + ',我的位置&to=' + data.detail.longitude + ',' + data.detail.latitude + ',' + data.detail.name + '&mode=car&policy=1&src=mypage&coordinate=gaode&callnative=0'
            // }else {
            //   showFailToast(res.msg);
            // }
          }
        });
      }


    }

    const clockCard = () => {
        //先获取定位,然后再扫码打卡
        eshimin.getBarcode({
            onResponse:  (resCode)=> {
              let codeJson = JSON.parse(resCode);
              //获取当前位置
              //埋点上报
              if(codeJson.barcode == data.detail.code){
                eshimin.bytedanceApplogEvent({
                  eventName: 'check_click_button', // 例子
                  eventParams: {'venue_name': data.detail.name} ,//例子
                  onResponse:  (res)=> {
                    console.log("bytedanceApplogEvent responseData:" + res);
                  }
                })
              }


              if(codeJson.barcode && codeJson.barcode.length > 0){
                eshimin.getAddress({
                  onResponse: (resAddress) => {
                    resAddress = JSON.parse(resAddress);
                    // if(resAddress.code === 200){
                      let params = {'code': codeJson.barcode, 'longitude': resAddress.longitude, 'latitude': resAddress.latitude}
                      showLoading('加载中')
                      proxy.$http.post("/wenwu-user/bookstore/clock", params).then(res => {
                        clearToast()
                        if(res.data.code == 200){
                          $router.push({name:'getDigitalSuccess',query:res.data.data})
                        }else if(res.data.code == 7008) {
                          Dialog.alert({
                            confirmButtonColor:'#333',
                            messageAlign:'left',
                            message: res.data.message,
                          }).then(() => {
                            // on close
                          });
                        } else{
                          showFailToast(res.data.message)
                        }
                      }).catch((err) => {
                        showFailToast(err.message, 1000);
                      })
                    // }else {
                    //   showFailToast(resAddress.msg);
                    // }


                  }
                });
              }



            }


        });
    }

    loadData();
    return {
      data,
      back,
      toGuide,
      clockCard
    };
  }
};
</script>

<style scoped lang="scss" src="./index.scss">

</style>


